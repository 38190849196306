import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  width: 100%;
  height: auto;
  font-size: 13px;
  font-weight: normal;
  color: #545454;
  margin: 0px;
  padding: 20px 0px;
  text-align: center;
  p {
    margin: 0px;
    padding: 0px;
  }
`;
const Footer = () => {
  return (
    <StyledFooter>
      <p>
        &copy; Copyrights AnimalDrop {new Date().getFullYear()}. All Rights
        Reserved.
      </p>
    </StyledFooter>
  );
};

export default Footer;
