import React from 'react';
import styled from 'styled-components';
import { withAppHOC } from '../../hoc';

const StyledDashboardContainer = styled.section`
  width: 95%;
  max-width: 1600px;
  height: auto;
  min-height: 300px;
  margin: 30px auto;
  padding: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #c1c1c1;
  border-radius: 15px;
  background-color: #ffffff;
`;

const StyledHeading = styled.h2`
  font-size: 28px;
  font-weight: normal;
  color: #000000;
  margin: 0px;
  padding: 15px;
  text-align: center;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
`;

const Dashboard = (props) => {
  return (
    <StyledDashboardContainer>
      <StyledHeading>Welcome to AnimalDrop Admin</StyledHeading>
    </StyledDashboardContainer>
  );
};

export default withAppHOC(Dashboard);
