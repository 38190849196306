import React, { useState, useEffect } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  NumberInput,
  Dropdown,
  MessageBox,
  CreateButton,
  ResetButton,
  PasswordInput,
  FileInput,
} from '../../../components/FormElements';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getBase64, getReturnUrl, uploadFile } from '../../../utils';
import { Grid, GridItem } from '../../../components/Grid';
import moment from 'moment';

const UsersCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [fullName, setFullName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [mobileVerificationStatus, setMobileVerificationStatus] = useState('');
  const [gender, setGender] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [photo, setPhoto] = useState([]);
  const [photoBase64, setPhotoBase64] = useState('');
  const [userStatus, setUserStatus] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    try {
      let processedFile = '';
      if (photoBase64 !== '') {
        processedFile = await uploadFile('Image', photoBase64, 'user', false);
      }
      API.post('/accounts-management/users', {
        fullName: fullName,
        mobileNumber: mobileNumber,
        mobileVerificationStatus: mobileVerificationStatus,
        gender: gender,
        dateOfBirth: dateOfBirth,
        userStatus: userStatus,
        password: password,
        confirmPassword: confirmPassword,
        photo: processedFile,
      })
        .then((response) => {
          const { status, message } = response.data;
          setResponseStatus(status);
          setResponseMessage(message);
          if (status === API_RESPONSE_TYPES.SUCCESS) {
            resetForm();
            setTimeout(() => {
              resetResponseData();
              window.location.href = getReturnUrl(PERMISSION_TYPES.CREATE);
            }, 3000);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {
      setResponseStatus(API_RESPONSE_TYPES.FAILURE);
      setResponseMessage(error.message);
      setIsLoading(false);
    }
  };

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setFullName('');
    setMobileNumber('');
    setMobileVerificationStatus('');
    setGender('');
    setDateOfBirth('');
    setPhoto([]);
    setPhotoBase64('');
    setUserStatus('');
    setPassword('');
    setConfirmPassword('');
  };

  useEffect(() => {
    if (photo?.length > 0) {
      getBase64(photo[0], setPhotoBase64);
    }
  }, [photo]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form
          method='POST'
          action='#'
          onSubmit={onSubmit}
          enctype='multipart/form-data'
        >
          <Fieldset>
            <Label required>Full Name</Label>
            <TextInput
              value={fullName}
              onChange={setFullName}
              placeholder='Please enter full name'
              disabled={isLoading}
            />
          </Fieldset>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>Mobile Number</Label>
                <NumberInput
                  placeholder='Please enter mobile number'
                  value={mobileNumber}
                  onChange={setMobileNumber}
                  maxLength={10}
                  autoComplete='off'
                  disabled={isLoading}
                  pattern={`[0-9]*`}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Mobile Verification Status</Label>
                <Dropdown
                  placeholder='Please select mobile verification status'
                  value={mobileVerificationStatus}
                  onChange={setMobileVerificationStatus}
                  options={[
                    {
                      title: '-- SELECT MOBILE VERIFICATION STATUS --',
                      value: '',
                    },
                    {
                      title: 'Verified',
                      value: 'Verified',
                    },
                    {
                      title: 'Not Verified',
                      value: 'Not Verified',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label>Gender</Label>
                <Dropdown
                  placeholder='Please select gender'
                  value={gender}
                  onChange={setGender}
                  options={[
                    {
                      title: '-- SELECT GENDER --',
                      value: '',
                    },
                    {
                      title: 'Male',
                      value: 'Male',
                    },
                    {
                      title: 'Female',
                      value: 'Female',
                    },
                    {
                      title: 'Others',
                      value: 'Others',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Date Of Birth</Label>
                <TextInput
                  type='date'
                  value={dateOfBirth}
                  onChange={setDateOfBirth}
                  placeholder='Please select date of birth'
                  disabled={isLoading}
                  max={moment().format('YYYY-MM-DD')}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Photo ( 250 X 250 )</Label>
            <FileInput
              files={photo}
              onChange={setPhoto}
              accept='image/*'
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label required>User Status</Label>
            <Dropdown
              placeholder='Please select user status'
              value={userStatus}
              onChange={setUserStatus}
              options={[
                {
                  title: '-- SELECT USER STATUS --',
                  value: '',
                },
                {
                  title: 'Active',
                  value: 'Active',
                },
                {
                  title: 'Inactive',
                  value: 'Inactive',
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>Password</Label>
                <PasswordInput
                  placeholder='Please enter password'
                  value={password}
                  onChange={setPassword}
                  autoComplete='off'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Confirm Password</Label>
                <PasswordInput
                  placeholder='Please enter confirm password'
                  value={confirmPassword}
                  onChange={setConfirmPassword}
                  autoComplete='off'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading} type='submit'>
              Create
            </CreateButton>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(UsersCreate);
