import React, { useState } from 'react';
import { withAppHOC } from '../../hoc';
import PageHeading from '../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import {
  Fieldset,
  Form,
  Label,
  MessageBox,
  TextInput,
  Button,
  ResetButton,
  FormContainer,
  UpdateButton,
} from '../../components/FormElements';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';
import { StyledFiltersContainer } from '../../components/Styled';
import OverlayLoader from '../../components/OverlayLoader/OverlayLoader';

const Localization = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [stringKey, setStringKey] = useState('');
  const [localizations, setLocalizations] = useState([]);

  const readData = (e) => {
    e?.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    setLocalizations([]);
    API.get(`/localization?stringKey=${stringKey}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        }
        setLocalizations(data);
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateData = (e) => {
    e?.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.put(`/localization?stringKey=${stringKey}`, {
      stringValues: localizations.map(({ languageCode, stringValue }) => ({
        languageCode,
        stringValue,
      })),
    })
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        setTimeout(() => {
          setResponseStatus('');
          setResponseMessage('');
        }, 3000);
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resetForm = () => {
    setStringKey('');
    setLocalizations([]);
  };

  const reloadData = () => {
    readData();
  };

  return (
    <>
      <PageHeading
        {...props}
        canReload={localizations.length > 0}
        reloadData={reloadData}
      />
      <StyledFiltersContainer className={'show'}>
        <Form method='POST' action='#' onSubmit={readData}>
          <Fieldset>
            <Label>String Key</Label>
            <TextInput
              value={stringKey}
              onChange={setStringKey}
              placeholder='Please enter string key'
              maxLength={10000000}
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading === true} type='submit'>
              Fetch
            </Button>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === '' && localizations.length === 0 && isLoading
        }
      />
      {localizations.length > 0 && (
        <FormContainer>
          <Form method='POST' action='#' onSubmit={updateData}>
            {localizations.map(
              ({ languageName, languageCode, stringValue }, index) => (
                <Fieldset>
                  <Label>
                    {languageName} ( {languageCode} )
                  </Label>
                  <TextInput
                    value={stringValue}
                    onChange={(value) => {
                      const newLocalizations = [...localizations];
                      newLocalizations[index].stringValue = value;
                      setLocalizations(newLocalizations);
                    }}
                    placeholder='Please enter string value'
                    disabled={isLoading}
                  />
                </Fieldset>
              )
            )}
            {responseStatus && (
              <Fieldset>
                <MessageBox status={responseStatus} message={responseMessage} />
              </Fieldset>
            )}
            <Fieldset>
              <UpdateButton disabled={isLoading} type='submit'>
                Update
              </UpdateButton>
            </Fieldset>
          </Form>
          <OverlayLoader showLoader={isLoading} />
        </FormContainer>
      )}
    </>
  );
};

export default withAppHOC(Localization);
