import {
  faHome,
  faUsers,
  faGears,
  faDatabase,
  faLanguage,
} from '@fortawesome/free-solid-svg-icons';
import { MODULES, PERMISSION_TYPES, ROUTE_TYPES } from './index';
import Login from '../containers/Auth/Login';
import ForgotPassword from '../containers/Auth/ForgotPassword';
import Profile from '../containers/Auth/Profile';
import ChangePassword from '../containers/Auth/ChangePassword';
import Dashboard from '../containers/Dashboard/Dashboard';
import RolesAndPermissionsList from '../containers/AccountsManagement/RolesAndPermissions/RolesAndPermissionsList';
import RolesAndPermissionsCreate from '../containers/AccountsManagement/RolesAndPermissions/RolesAndPermissionsCreate';
import RolesAndPermissionsUpdate from '../containers/AccountsManagement/RolesAndPermissions/RolesAndPermissionsUpdate';
import AccountsList from '../containers/AccountsManagement/Accounts/AccountsList';
import AccountsCreate from '../containers/AccountsManagement/Accounts/AccountsCreate';
import AccountsUpdate from '../containers/AccountsManagement/Accounts/AccountsUpdate';
import UsersList from '../containers/AccountsManagement/Users/UsersList';
import UsersCreate from '../containers/AccountsManagement/Users/UsersCreate';
import UsersUpdate from '../containers/AccountsManagement/Users/UsersUpdate';
import LanguagesList from '../containers/Configurations/Languages/LanguagesList';
import LanguagesCreate from '../containers/Configurations/Languages/LanguagesCreate';
import LanguagesUpdate from '../containers/Configurations/Languages/LanguagesUpdate';
import SMSGateway from '../containers/Configurations/SMSGateway/SMSGateway';
import AnimalsList from '../containers/ContentManagement/Animals/AnimalsList';
import AnimalsCreate from '../containers/ContentManagement/Animals/AnimalsCreate';
import AnimalsUpdate from '../containers/ContentManagement/Animals/AnimalsUpdate';
import PostsList from '../containers/ContentManagement/Posts/PostsList';
import PendingPostsList from '../containers/ContentManagement/Posts/PendingPostsList';
import Localization from '../containers/Localization/Localization';

const {
  DASHBOARD,
  ACCOUNTS_MANAGEMENT,
  ROLES_AND_PERMISSIONS,
  ACCOUNTS,
  USERS,
  CONFIGURATIONS,
  LANGUAGES,
  SMS_GATEWAY,
  CONTENT_MANAGEMENT,
  ANIMALS,
  POSTS,
  PENDING_POSTS,
  LOCALIZATION,
} = MODULES;
const { CREATE, READ, UPDATE } = PERMISSION_TYPES;
const { ALLOW_IF_LOGGEDIN, ALLOW_IF_NOT_LOGGEDIN } = ROUTE_TYPES;

const ROUTES = [
  {
    path: '/',
    title: 'Home',
    icon: faHome,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: DASHBOARD,
    permissionRequired: READ,
    component: Dashboard,
    hideHeading: true,
    hideBreadcrumbs: true,
    exact: true,
  },
  {
    path: '/login',
    title: 'Login',
    routeType: ALLOW_IF_NOT_LOGGEDIN,
    component: Login,
    hideInNavigation: true,
  },
  {
    path: '/forgot-password',
    title: 'Forgot Password',
    routeType: ALLOW_IF_NOT_LOGGEDIN,
    component: ForgotPassword,
    hideInNavigation: true,
  },
  {
    path: '/profile',
    title: 'My Profile',
    routeType: ALLOW_IF_LOGGEDIN,
    component: Profile,
    hideInNavigation: true,
    showInHeader: true,
  },
  {
    path: '/change-password',
    title: 'Change Password',
    routeType: ALLOW_IF_LOGGEDIN,
    component: ChangePassword,
    hideInNavigation: true,
    showInHeader: true,
  },
  {
    path: '/accounts-management',
    title: 'Accounts Management',
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: ACCOUNTS_MANAGEMENT,
    permissionRequired: READ,
    icon: faUsers,
    isDummy: true,
    subRoutes: [
      {
        path: '/accounts-management/roles-and-permissions',
        title: 'Roles & Permissions',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: ROLES_AND_PERMISSIONS,
        permissionRequired: READ,
        component: RolesAndPermissionsList,
        masterModule: ACCOUNTS_MANAGEMENT,
        subRoutes: [
          {
            path: '/accounts-management/roles-and-permissions/create',
            title: 'Create Roles & Permissions',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ROLES_AND_PERMISSIONS,
            permissionRequired: CREATE,
            component: RolesAndPermissionsCreate,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/accounts-management/roles-and-permissions/update/:id',
            title: 'Update Roles & Permissions',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ROLES_AND_PERMISSIONS,
            permissionRequired: UPDATE,
            component: RolesAndPermissionsUpdate,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/accounts-management/accounts',
        title: 'Accounts',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: ACCOUNTS,
        permissionRequired: READ,
        component: AccountsList,
        masterModule: ACCOUNTS_MANAGEMENT,
        subRoutes: [
          {
            path: '/accounts-management/accounts/create',
            title: 'Create Account',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ACCOUNTS,
            permissionRequired: CREATE,
            component: AccountsCreate,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/accounts-management/accounts/update/:id',
            title: 'Update Account',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ACCOUNTS,
            permissionRequired: UPDATE,
            component: AccountsUpdate,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/accounts-management/users',
        title: 'Users',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: USERS,
        permissionRequired: READ,
        component: UsersList,
        masterModule: ACCOUNTS_MANAGEMENT,
        subRoutes: [
          {
            path: '/accounts-management/users/create',
            title: 'Create Customer',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: USERS,
            permissionRequired: CREATE,
            component: UsersCreate,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/accounts-management/users/update/:id',
            title: 'Update Customer',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: USERS,
            permissionRequired: UPDATE,
            component: UsersUpdate,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
    ],
  },
  {
    path: '/configurations',
    title: 'Configurations',
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: CONFIGURATIONS,
    permissionRequired: READ,
    icon: faGears,
    isDummy: true,
    subRoutes: [
      {
        path: '/configurations/languages',
        title: 'Languages',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: LANGUAGES,
        permissionRequired: READ,
        component: LanguagesList,
        masterModule: CONFIGURATIONS,
        subRoutes: [
          {
            path: '/configurations/languages/create',
            title: 'Create Language',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: LANGUAGES,
            permissionRequired: CREATE,
            component: LanguagesCreate,
            masterModule: CONFIGURATIONS,
            hideInNavigation: true,
          },
          {
            path: '/configurations/languages/update/:id',
            title: 'Update Language',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: LANGUAGES,
            permissionRequired: UPDATE,
            component: LanguagesUpdate,
            masterModule: CONFIGURATIONS,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/configurations/sms-gateway',
        title: 'SMS Gateway',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: SMS_GATEWAY,
        permissionRequired: READ,
        component: SMSGateway,
        masterModule: CONFIGURATIONS,
      },
    ],
  },
  {
    path: '/content-management',
    title: 'Content Management',
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: CONTENT_MANAGEMENT,
    permissionRequired: READ,
    icon: faDatabase,
    isDummy: true,
    subRoutes: [
      {
        path: '/content-management/animals',
        title: 'Animals',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: ANIMALS,
        permissionRequired: READ,
        component: AnimalsList,
        masterModule: CONTENT_MANAGEMENT,
        subRoutes: [
          {
            path: '/content-management/animals/create',
            title: 'Create Animal',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ANIMALS,
            permissionRequired: CREATE,
            component: AnimalsCreate,
            masterModule: CONTENT_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/content-management/animals/update/:id',
            title: 'Update Animal',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ANIMALS,
            permissionRequired: UPDATE,
            component: AnimalsUpdate,
            masterModule: CONTENT_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/content-management/posts',
        title: 'Posts',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: POSTS,
        permissionRequired: READ,
        component: PostsList,
        masterModule: CONTENT_MANAGEMENT,
      },
      {
        path: '/content-management/pending-posts',
        title: 'Pending Posts',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: PENDING_POSTS,
        permissionRequired: READ,
        component: PendingPostsList,
        masterModule: CONTENT_MANAGEMENT,
      },
    ],
  },
  {
    path: '/localization',
    title: 'Localization',
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: LOCALIZATION,
    permissionRequired: READ,
    component: Localization,
    icon: faLanguage,
  },
];

export { ROUTES };
