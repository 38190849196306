import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDrag, useDrop } from 'react-dnd';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  FileInput,
  Dropdown,
  MessageBox,
  CreateButton,
  ResetButton,
  Button,
  Legend,
  CustomConfirmButton,
} from '../../../components/FormElements';
import Accordination from '../../../components/Accordination/Accordination';
import { Grid, GridItem } from '../../../components/Grid';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getBase64, getReturnUrl, uploadFile } from '../../../utils';

const StyledDragableContainer = styled.section`
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

const StyledFieldsContainer = styled.section`
  padding: 25px;
`;

const DraggableField = ({
  index,
  field,
  moveField,
  onChange,
  isLoading,
  fields,
  removeField,
}) => {
  const [, ref] = useDrag({
    type: 'FIELD',
    item: { index },
  });

  const [, drop] = useDrop({
    accept: 'FIELD',
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveField(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const fieldsOptions = [{ title: '-- SELECT DEPENDENT FIELD --', value: '' }];
  fields
    .filter((_, itemIndex) => itemIndex !== index)
    .forEach(({ label, fieldPosition }) => {
      fieldsOptions.push({
        title: label ? label : `Field - ${fieldPosition}`,
        value: label ? label : `Field - ${fieldPosition}`,
      });
    });

  return (
    <StyledDragableContainer ref={(node) => ref(drop(node))}>
      <Accordination autoExpanded={true} header={`Field - ${index + 1}`}>
        <StyledFieldsContainer>
          <Fieldset style={{ textAlign: 'right' }}>
            <CustomConfirmButton onClick={() => removeField(index)} />
          </Fieldset>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label>Field Label</Label>
                <TextInput
                  value={field.label}
                  onChange={(value) => onChange('label', value, index)}
                  placeholder='Please enter label'
                  maxLength={100}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Required</Label>
                <Dropdown
                  placeholder='Please select if the field is required'
                  value={field.required}
                  onChange={(value) => onChange('required', value, index)}
                  options={[
                    {
                      title: '-- SELECT REQUIRED --',
                      value: '',
                    },
                    {
                      title: 'Yes',
                      value: 'Yes',
                    },
                    {
                      title: 'No',
                      value: 'No',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Placeholder</Label>
                <TextInput
                  value={field.placeholder}
                  onChange={(value) => onChange('placeholder', value, index)}
                  placeholder='Please enter placeholder'
                  maxLength={100}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label>Field Type</Label>
                <Dropdown
                  placeholder='Please select field type'
                  value={field.type}
                  onChange={(value) => onChange('type', value, index)}
                  options={[
                    {
                      title: '-- SELECT TYPE --',
                      value: '',
                    },
                    {
                      title: 'TextInput',
                      value: 'TextInput',
                    },
                    {
                      title: 'NumberInput',
                      value: 'NumberInput',
                    },
                    {
                      title: 'DatePicker',
                      value: 'DatePicker',
                    },
                    {
                      title: 'DropDown',
                      value: 'DropDown',
                    },
                    {
                      title: 'YesNoButtons',
                      value: 'YesNoButtons',
                    },
                    {
                      title: 'FileSelector',
                      value: 'FileSelector',
                    },
                    {
                      title: 'TextArea',
                      value: 'TextArea',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Default Value</Label>
                <TextInput
                  value={field.defaultValue}
                  onChange={(value) => onChange('defaultValue', value, index)}
                  placeholder='Please enter default value'
                  maxLength={100}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Options</Label>
                <TextInput
                  value={field.options}
                  onChange={(value) => onChange('options', value, index)}
                  placeholder='Please enter options json'
                  maxLength={100000000000000}
                  disabled={isLoading || field.type !== 'DropDown'}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Regex</Label>
            <TextInput
              value={field.regex}
              onChange={(value) => onChange('regex', value, index)}
              placeholder='Please enter regex'
              maxLength={100000}
              disabled={isLoading}
            />
          </Fieldset>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label>Is Dependent Field</Label>
                <Dropdown
                  placeholder='Please select if field is dependent'
                  value={field.isDependentField}
                  onChange={(value) =>
                    onChange('isDependentField', value, index)
                  }
                  options={[
                    {
                      title: '-- SELECT IF FIELD IS DEPENDENT --',
                      value: '',
                    },
                    {
                      title: 'Yes',
                      value: 'Yes',
                    },
                    {
                      title: 'No',
                      value: 'No',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Dependent Field Id</Label>
                <Dropdown
                  placeholder='Please select dependent field'
                  value={field.dependentFieldsId}
                  onChange={(value) =>
                    onChange('dependentFieldsId', value, index)
                  }
                  options={fieldsOptions}
                  disabled={isLoading || field.isDependentField !== 'Yes'}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Dependent Fields Values</Label>
                <TextInput
                  value={field.dependentFieldsValues}
                  onChange={(value) =>
                    onChange('dependentFieldsValues', value, index)
                  }
                  placeholder='Please enter dependency field value or array of values'
                  maxLength={100000000000000}
                  disabled={isLoading || field.isDependentField !== 'Yes'}
                />
              </Fieldset>
            </GridItem>
          </Grid>
        </StyledFieldsContainer>
      </Accordination>
    </StyledDragableContainer>
  );
};

const AnimalsCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [animalName, setAnimalName] = useState('');
  const [animalIcon, setAnimalIcon] = useState([]);
  const [animalIconBase64, setAnimalIconBase64] = useState('');
  const [animalStatus, setAnimalStatus] = useState('');
  const [fields, setFields] = useState([]);

  const AddField = () => {
    setFields((prevFields) => [
      ...prevFields,
      {
        label: '',
        required: 'No',
        placeholder: '',
        type: '',
        defaultValue: '',
        options: '',
        regex: '',
        isDependentField: 'No',
        dependentFieldsId: '',
        dependentFieldsValues: '',
        fieldPosition: prevFields.length + 1,
      },
    ]);
  };

  const removeField = (index) => {
    const newFields = [...fields];
    newFields.splice(index, 1);
    newFields.forEach((field, index) => {
      field.fieldPosition = index + 1;
    });
    setFields(newFields);
  };

  const moveField = async (fromIndex, toIndex) => {
    const newFields = [...fields];
    const [movedField] = newFields.splice(fromIndex, 1);
    newFields.splice(toIndex, 0, {
      ...movedField,
      fieldPosition: toIndex + 1,
    });
    newFields.forEach((field, index) => {
      field.fieldPosition = index + 1;
    });
    setFields(newFields);
  };

  const setFieldsValues = (name, value, index) => {
    const newFields = [...fields];
    newFields[index][name] = value;
    setFields(newFields);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    try {
      let processedFile = '';
      if (animalIconBase64 !== '') {
        processedFile = await uploadFile(
          'Image',
          animalIconBase64,
          'animal',
          false
        );
      }
      const formData = {
        animalName: animalName,
        animalIcon: processedFile,
        animalStatus: animalStatus,
        fields: fields,
      };
      API.post('/content-management/animals', formData)
        .then((response) => {
          const { status, message } = response.data;
          setResponseStatus(status);
          setResponseMessage(message);
          if (status === API_RESPONSE_TYPES.SUCCESS) {
            resetForm();
            setTimeout(() => {
              resetResponseData();
              window.location.href = getReturnUrl(PERMISSION_TYPES.CREATE);
            }, 3000);
          }
        })
        .catch((error) => {
          throw new Error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {
      setResponseStatus(API_RESPONSE_TYPES.FAILURE);
      setResponseMessage(error.message);
      setIsLoading(false);
    }
  };

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setAnimalName('');
    setAnimalIcon([]);
    setAnimalIconBase64('');
    setAnimalStatus('');
    setFields([]);
  };

  useEffect(() => {
    if (animalIcon?.length > 0) {
      getBase64(animalIcon[0], setAnimalIconBase64);
    }
  }, [animalIcon]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form
          method='POST'
          action='#'
          onSubmit={onSubmit}
          enctype='multipart/form-data'
        >
          <Fieldset>
            <Label required>Animal Name</Label>
            <TextInput
              value={animalName}
              onChange={setAnimalName}
              placeholder='Please enter animal name'
              maxLength={100}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Animal Icon ( 250 X 250 )</Label>
            <FileInput
              files={animalIcon}
              onChange={setAnimalIcon}
              accept='image/*'
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label required>Animal Status</Label>
            <Dropdown
              placeholder='Please select animal status'
              value={animalStatus}
              onChange={setAnimalStatus}
              options={[
                {
                  title: '-- SELECT ANIMAL STATUS --',
                  value: '',
                },
                {
                  title: 'Active',
                  value: 'Active',
                },
                {
                  title: 'Inactive',
                  value: 'Inactive',
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset forLegend>
            <Legend>Fields</Legend>
            <Fieldset>
              <Button onClick={AddField} type='button'>
                Add Field
              </Button>
            </Fieldset>
            {fields?.map((field, index) => (
              <DraggableField
                key={index}
                index={index}
                field={field}
                moveField={moveField}
                onChange={(name, value, index) =>
                  setFieldsValues(name, value, index)
                }
                fields={fields}
                removeField={removeField}
              />
            ))}
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading} type='submit'>
              Create
            </CreateButton>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(AnimalsCreate);
