import React from 'react';
import styled from 'styled-components';

const StyledLogoContainer = styled.section`
  width: ${({ type }) => (type === 'auth' ? '100px' : 'auto')};
  height: ${({ type }) => (type === 'auth' ? 'auto' : '50px')};
  margin: ${({ type }) => (type === 'auth' ? '0px auto' : '2.5px auto')};
  padding: 0px 5px;
  display: flex;
  align-items: center;
`;

const StyledLogo = styled.img`
  width: ${({ type }) => (type === 'auth' ? '100px' : '100%')};
  height: 100%;
  margin: 0 auto;
  padding: 0px;
`;

const Logo = ({ width, height, ...rest }) => {
  return (
    <StyledLogoContainer width={width} height={height} {...rest}>
      <StyledLogo src={`/logo.png`} alt={`AnimalDrop`} />
    </StyledLogoContainer>
  );
};

export default Logo;
