import React, { useState } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  MessageBox,
  CreateButton,
  ResetButton,
} from '../../../components/FormElements';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getReturnUrl } from '../../../utils';
import { Grid, GridItem } from '../../../components/Grid';

const LanguagesCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [languageName, setLanguageName] = useState('');
  const [languageCode, setLanguageCode] = useState('');
  const [languageShortName, setLanguageShortName] = useState('');
  const [languageBackgroundColor, setLanguageBackgroundColor] = useState('');
  const [languageBorderColor, setLanguageBorderColor] = useState('');
  const [languageTextColor, setLanguageTextColor] = useState('');
  const [languageStatus, setLanguageStatus] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.post('/configurations/languages', {
      languageName: languageName,
      languageCode: languageCode,
      languageShortName: languageShortName,
      languageBackgroundColor: languageBackgroundColor,
      languageBorderColor: languageBorderColor,
      languageTextColor: languageTextColor,
      languageStatus: languageStatus,
    })
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.CREATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setLanguageName('');
    setLanguageCode('');
    setLanguageShortName('');
    setLanguageBackgroundColor('');
    setLanguageBorderColor('');
    setLanguageTextColor('');
    setLanguageStatus('');
  };

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Language Name</Label>
                <TextInput
                  value={languageName}
                  onChange={setLanguageName}
                  placeholder='Please enter language name'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Language Code</Label>
                <TextInput
                  value={languageCode}
                  onChange={setLanguageCode}
                  placeholder='Please enter language code'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Label>Language Short Name</Label>
              <TextInput
                value={languageShortName}
                onChange={setLanguageShortName}
                placeholder='Please enter language short name'
                disabled={isLoading}
              />
            </GridItem>
          </Grid>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label>Language Background Color</Label>
                <TextInput
                  type='color'
                  value={languageBackgroundColor}
                  onChange={setLanguageBackgroundColor}
                  placeholder='Please select language background color'
                  disabled={isLoading}
                  style={{ height: '42px', padding: 0, width: '100%' }}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Language Border Color</Label>
                <TextInput
                  type='color'
                  value={languageBorderColor}
                  onChange={setLanguageBorderColor}
                  placeholder='Please select language border color'
                  disabled={isLoading}
                  style={{ height: '42px', padding: 0, width: '100%' }}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Language Text Color</Label>
                <TextInput
                  type='color'
                  value={languageTextColor}
                  onChange={setLanguageTextColor}
                  placeholder='Please select language text color'
                  disabled={isLoading}
                  style={{ height: '42px', padding: 0, width: '100%' }}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label required>Language Status</Label>
            <Dropdown
              placeholder='Please select language status'
              value={languageStatus}
              onChange={setLanguageStatus}
              options={[
                {
                  title: '-- SELECT USER STATUS --',
                  value: '',
                },
                {
                  title: 'Active',
                  value: 'Active',
                },
                {
                  title: 'Inactive',
                  value: 'Inactive',
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading} type='submit'>
              Create
            </CreateButton>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(LanguagesCreate);
