import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  MessageBox,
  UpdateButton,
  ResetButton,
  Button,
} from '../../../components/FormElements';
import API from '../../../api';
import { API_RESPONSE_TYPES } from '../../../constants';
import { Grid, GridItem } from '../../../components/Grid';

const StyledMicroButton = styled(Button)`
  font-size: 12px;
  padding: 10px;
  margin: 10px 10px 10px 0px;
`;

const SMSGateway = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [apiEndpoint, setApiEndpoint] = useState('');
  const [requestMethod, setRequestMethod] = useState('');
  const [responseType, setResponseType] = useState('');
  const [requestBody, setRequestBody] = useState('');
  const [requestHeaders, setRequestHeaders] = useState('');
  const [statusKey, setStatusKey] = useState('');
  const [successValue, setSuccessValue] = useState('');
  const [failureValue, setFailureValue] = useState('');
  const [originalFormData, setOriginalFormData] = useState({});

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/configurations/sms-gateway`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            apiEndpoint,
            requestMethod,
            responseType,
            requestBody,
            requestHeaders,
            statusKey,
            successValue,
            failureValue,
          } = data;
          setApiEndpoint(apiEndpoint);
          setRequestMethod(requestMethod);
          setResponseType(responseType);
          setRequestBody(requestBody);
          setRequestHeaders(requestHeaders);
          setStatusKey(statusKey);
          setSuccessValue(successValue);
          setFailureValue(failureValue);
          setOriginalFormData({
            apiEndpoint,
            requestMethod,
            responseType,
            requestBody,
            requestHeaders,
            statusKey,
            successValue,
            failureValue,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      apiEndpoint,
      requestMethod,
      responseType,
      requestBody,
      requestHeaders,
      statusKey,
      successValue,
      failureValue,
    };
    API.put(`/configurations/sms-gateway`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const {
      apiEndpoint,
      requestMethod,
      responseType,
      requestBody,
      requestHeaders,
      statusKey,
      successValue,
      failureValue,
    } = originalFormData;
    setApiEndpoint(apiEndpoint);
    setRequestMethod(requestMethod);
    setResponseType(responseType);
    setRequestBody(requestBody);
    setRequestHeaders(requestHeaders);
    setStatusKey(statusKey);
    setSuccessValue(successValue);
    setFailureValue(failureValue);
  };

  const addText = (setMethod, value) => {
    setMethod((currentValue) => currentValue + value);
  };

  useEffect(() => {
    readData();
  }, []);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Fieldset>
            <Label required>API Endpoint</Label>
            <TextInput
              value={apiEndpoint}
              onChange={setApiEndpoint}
              placeholder='Please enter api endpoint url'
              disabled={isLoading}
            />
          </Fieldset>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>Request Method</Label>
                <Dropdown
                  placeholder='Please select request method'
                  value={requestMethod}
                  onChange={setRequestMethod}
                  options={[
                    {
                      title: '-- SELECT REQUEST METHOD --',
                      value: '',
                    },
                    {
                      title: 'GET',
                      value: 'GET',
                    },
                    {
                      title: 'POST',
                      value: 'POST',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Response Type</Label>
                <Dropdown
                  placeholder='Please select response type'
                  value={responseType}
                  onChange={setResponseType}
                  options={[
                    {
                      title: '-- SELECT RESPONSE TYPE --',
                      value: '',
                    },
                    {
                      title: 'JSON',
                      value: 'JSON',
                    },
                    {
                      title: 'XML',
                      value: 'XML',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label required>Request Body</Label>
            <TextInput
              value={requestBody}
              onChange={setRequestBody}
              placeholder='Please enter request body'
              disabled={isLoading}
            />
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() => addText(setRequestBody, '{{{TEMPLATES_ID}}}')}
            >
              TemplatesId
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() => addText(setRequestBody, '{{{NUMBER}}}')}
            >
              Mobile Number
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading === true}
              type='button'
              onClick={() => addText(setRequestBody, '{{{PARAMS}}}')}
            >
              Template Params
            </StyledMicroButton>
          </Fieldset>
          <Fieldset>
            <Label>Request Headers</Label>
            <TextInput
              value={requestHeaders}
              onChange={setRequestHeaders}
              placeholder='Please enter request headers'
              disabled={isLoading}
            />
          </Fieldset>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label>Status Key</Label>
                <TextInput
                  value={statusKey}
                  onChange={setStatusKey}
                  placeholder='Please enter status key'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Success Value</Label>
                <TextInput
                  value={successValue}
                  onChange={setSuccessValue}
                  placeholder='Please enter success value'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Failure Value</Label>
                <TextInput
                  value={failureValue}
                  onChange={setFailureValue}
                  placeholder='Please enter failure value'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading} type='submit'>
              Update
            </UpdateButton>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(SMSGateway);
