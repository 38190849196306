import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { withAppHOC } from '../../hoc';
import PageHeading from '../../components/PageHeader/PageHeader';
import OverlayLoader from '../../components/OverlayLoader/OverlayLoader';
import Modal from '../../components/Modal';
import { Image } from '../../components/Image';
import { Grid, GridItem } from '../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  NumberInput,
  Dropdown,
  FileInput,
  MessageBox,
  UpdateButton,
  ResetButton,
} from '../../components/FormElements';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';
import { getBase64, uploadFile } from '../../utils';
import moment from 'moment';

const StyledViewAttachmentButton = styled.span`
  font-size: 14px;
  width: calc(100% - 22px);
  height: auto;
  border: 1px solid #d0eeff;
  display: block;
  margin: 20px 0px 0px;
  background-color: #edf8ff;
  padding: 15px 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.5s;
  position: relative;
  &:hover {
    background-color: #d0eeff;
  }
`;

const Profile = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [showExistingImage, setShowExistingImage] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [fullName, setFullName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [gender, setGender] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [photo, setPhoto] = useState([]);
  const [photoBase64, setPhotoBase64] = useState('');
  const [originalFormData, setOriginalFormData] = useState({});
  const [editable, setEditable] = useState(false);

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const { fullName, mobileNumber, gender, dateOfBirth } = originalFormData;
    setFullName(fullName);
    setMobileNumber(mobileNumber);
    setGender(gender);
    setDateOfBirth(dateOfBirth);
    setPhoto([]);
    setPhotoBase64('');
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/auth/profile`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const { fullName, mobileNumber, gender, dateOfBirth, photo } = data;
          setFullName(fullName);
          setMobileNumber(mobileNumber);
          setGender(gender);
          setDateOfBirth(dateOfBirth);
          setPhoto([]);
          setPhotoBase64('');
          setOriginalFormData({
            fullName,
            mobileNumber,
            gender,
            dateOfBirth,
            photo,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    try {
      let processedFile = originalFormData?.photo;
      if (photoBase64 !== '') {
        processedFile = await uploadFile(
          'Image',
          photoBase64,
          'account',
          false
        );
      }
      const formData = {
        fullName,
        mobileNumber,
        gender,
        dateOfBirth,
        photo: processedFile,
      };
      API.put(`/auth/profile`, formData)
        .then((response) => {
          const { status, message } = response.data;
          setResponseStatus(status);
          setResponseMessage(message);
          if (status === API_RESPONSE_TYPES.SUCCESS) {
            setOriginalFormData(formData);
            setEditable(false);
            setTimeout(() => {
              resetResponseData();
            }, 3000);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {
      setResponseStatus(API_RESPONSE_TYPES.FAILURE);
      setResponseMessage(error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (photo?.length > 0) {
      getBase64(photo[0], setPhotoBase64);
    }
  }, [photo]);

  useEffect(() => {
    readData();
  }, [readData]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>Full Name</Label>
                <TextInput
                  value={fullName}
                  onChange={setFullName}
                  placeholder='Please enter full name'
                  maxLength={100}
                  disabled={!editable || isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Mobile Number</Label>
                <NumberInput
                  value={mobileNumber}
                  onChange={setMobileNumber}
                  placeholder='Please enter mobile number'
                  maxLength={1000}
                  disabled={true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label>Gender</Label>
                <Dropdown
                  placeholder='Please select gender'
                  value={gender}
                  onChange={setGender}
                  options={[
                    {
                      title: '-- SELECT GENDER --',
                      value: '',
                    },
                    {
                      title: 'Male',
                      value: 'Male',
                    },
                    {
                      title: 'Female',
                      value: 'Female',
                    },
                    {
                      title: 'Others',
                      value: 'Others',
                    },
                  ]}
                  disabled={!editable || isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Date Of Birth</Label>
                <TextInput
                  type='date'
                  value={dateOfBirth}
                  onChange={setDateOfBirth}
                  placeholder='Please select date of birth'
                  disabled={!editable || isLoading}
                  max={moment().format('YYYY-MM-DD')}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Photo</Label>
            <FileInput
              files={photo}
              onChange={setPhoto}
              accept='image/*'
              disabled={!editable || isLoading}
            />
            {originalFormData?.photo && (
              <StyledViewAttachmentButton
                onClick={() => {
                  setShowExistingImage(true);
                  setModalImage(originalFormData.photo);
                }}
              >
                Click here to view existing file
              </StyledViewAttachmentButton>
            )}
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          {!editable ? (
            <Fieldset>
              <UpdateButton
                type='button'
                onClick={(e) => {
                  e.preventDefault();
                  setEditable((editable) => !editable);
                }}
              >
                Edit
              </UpdateButton>
            </Fieldset>
          ) : (
            <Fieldset>
              <UpdateButton disabled={isLoading} type='submit'>
                Update
              </UpdateButton>
              <ResetButton
                disabled={isLoading}
                type='button'
                onClick={resetForm}
              >
                Reset
              </ResetButton>
            </Fieldset>
          )}
        </Form>
        <OverlayLoader showLoader={isLoading} />
        <Modal
          modalStatus={showExistingImage}
          setModalStatus={setShowExistingImage}
          hideCloseButton
        >
          <Image width={150} source={modalImage} alt={'Existing File'} />
        </Modal>
      </FormContainer>
    </>
  );
};

export default withAppHOC(Profile);
