import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import {
  CustomConfirmButton,
  MessageBox,
  Form,
  Fieldset,
  TextInput,
  Button,
  ResetButton,
  Label,
} from '../../../components/FormElements';
import { TableBuilder } from '../../../components/TableElements';
import Pagination from '../../../components/Pagination/Pagination';
import FiltersButton from '../../../components/FiltersButton/FiltersButton';
import { StyledFiltersContainer, StyledSpan } from '../../../components/Styled';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import {
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
  formatCurrency,
} from '../../../utils';

const PostsList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [isFiltering, setIsFiltering] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 10,
  });
  const [posts, setPosts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const readData = useCallback(
    (searchQuery = '') => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      setPosts([]);
      API.get(
        `/content-management/posts/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&searchQuery=${searchQuery}`
      )
        .then((response) => {
          const { status, message, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.FAILURE) {
              setResponseStatus(status);
              setResponseMessage(message);
              setTimeout(() => {
                setResponseStatus('');
                setResponseMessage('');
              }, 3000);
            } else {
              setTotalRecords(pageInfo?.totalRecords);
            }
            setPosts(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const deleteData = (id) => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.delete(
      `/content-management/posts/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&searchQuery=${searchQuery}`
    )
      .then((response) => {
        const { status, message } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          reloadData();
        } else {
          setIsLoading(false);
          setResponseStatus(status);
          setResponseMessage(message);
          setTimeout(() => {
            setResponseStatus('');
            setResponseMessage('');
          }, 3000);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      });
  };

  const reloadData = () => {
    readData(searchQuery);
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  const resetForm = () => {
    setSearchQuery('');
    readData();
  };

  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      readData(searchQuery);
    }
  }, [paginationData, readData]);

  const { accountsPermissions, subRoutes } = props;
  const canDelete =
    accountsPermissions.includes(PERMISSION_TYPES.DELETE) || false;

  return (
    <>
      <PageHeading
        {...props}
        showCreate={true}
        canReload={true}
        reloadData={reloadData}
      />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? 'show' : ''}>
        <Form method="POST" action="#" onSubmit={filterData}>
          <Fieldset>
            <Label>Search</Label>
            <TextInput
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="Search Users Mobile Number"
              maxLength={100}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading} type="submit">
              Filter
            </Button>
            <ResetButton disabled={isLoading} type="button" onClick={resetForm}>
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={responseStatus === '' && posts.length === 0 && isLoading}
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          posts.length === 0
        }
        message={`No posts found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {posts.length > 0 && (
        <>
          <TableBuilder
            isLoading={posts.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: '',
                dataSelector: 'id',
                sticky: true,
                canSort: false,
                width: '50px',
                CellRenderer: (value) => (
                  <>
                    {canDelete && (
                      <CustomConfirmButton onClick={() => deleteData(value)} />
                    )}
                  </>
                ),
              },
              {
                title: 'User Details',
                dataSelector: 'fullName',
                dataType: 'string',
                CellRenderer: (value, item) => (
                  <>
                    {value}
                    <StyledSpan>{item?.mobileNumber}</StyledSpan>
                  </>
                ),
              },
              {
                title: 'Post Details',
                dataSelector: 'title',
                dataType: 'string',
                CellRenderer: (value, item) => (
                  <>
                    {value}
                    <StyledSpan>Address : {item?.address}</StyledSpan>
                    <StyledSpan>Animal : {item?.animalName}</StyledSpan>
                    {item?.fields?.length > 0 &&
                      item?.fields?.map(({ label, value }, index) => {
                        return (
                          <StyledSpan>
                            {label} : {value ? value : 'N/A'}
                          </StyledSpan>
                        );
                      })}
                  </>
                ),
              },
              {
                title: 'Post Media',
                dataSelector: 'media',
                dataType: 'string',
                CellRenderer: (value) => (
                  <>
                    {value?.length > 0 &&
                      value?.map(({ location, mediaType }, index) => {
                        return (
                          <>
                            <a
                              href={location}
                              target="_blank"
                              rel="noreferrer"
                              key={`m-index`}
                            >
                              {mediaType}
                            </a>
                            <br></br>
                          </>
                        );
                      })}
                  </>
                ),
              },
              {
                title: 'Price',
                dataSelector: 'price',
                dataType: 'string',
                CellRenderer: (value) => <>{formatCurrency(value)}</>,
              },
              {
                title: 'Is Negotiable',
                dataSelector: 'isNegotiable',
                dataType: 'string',
              },
              {
                title: 'Is Sold',
                dataSelector: 'isSold',
                dataType: 'string',
              },
              {
                title: 'Approval Status',
                dataSelector: 'approvalStatus',
                dataType: 'string',
              },
              {
                title: 'Created On',
                dataSelector: 'createdOn',
                dataType: 'date',
                CellRenderer: (value) =>
                  moment(value).format('YYYY-MM-DD hh:mm:ss A'),
              },
            ]}
            tableData={posts}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(PostsList);
