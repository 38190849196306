import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Image } from '../Image';

const StyledFileInputContainer = styled.section`
  width: calc(100% - 4px);
  height: auto;
  min-height: 200px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed;' : 'pointer;')};
  background-color: ${({ disabled }) => (disabled ? '#fafafa;' : '#f8f8f8')};
  transition: background-color 0.5s;
  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#fafafa;' : '#f1f1f1')};
  }
  svg {
    font-size: 70px;
    margin: 0px;
    padding: 0px;
    color: #e1e1e1;
  }
  p {
    font-size: 14px;
    margin: 30px 0px 0px;
    padding: 0px;
    color: #a7a7a7;
  }
`;

const StyledInput = styled.input`
  display: none;
`;

const StyledFilesListContainer = styled.section`
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 0px;
  display: block;
  p {
    font-size: 14px;
    color: #000;
    margin: 0px;
    padding: 0px;
  }
  svg {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 15px;
    color: #ff6060;
    margin: 0px;
    padding: 0px;
    cursor: pointer;
    &:hover {
      color: red;
    }
  }
`;

const StyledPreviewComponent = styled.section`
  width: calc(100% - 42px);
  height: auto;
  max-height: 300px;
  margin: 15px 0px;
  background-color: #f8f8f8;
  border: 1px solid #e5e5e5;
  padding: 15px 30px 15px 10px;
  border-radius: 5px;
  position: relative;
  img {
    width: 300px;
    height: 200px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
`;

const FileInput = ({
  files = [],
  onChange = () => {},
  preview = false,
  multiple = false,
  disabled = false,
  ...rest
}) => {
  const inputRef = useRef(null);
  const [filesList, setFilesList] = useState([]);
  const [selectedFilesDetails, setSelectedFilesDetails] = useState([]);

  const openFileSelector = () => {
    inputRef.current.click();
  };

  const onChangeInput = (e) => {
    e.target.files.length > 0 && setFilesList(e.target.files);
  };

  const generateSelectedFilesDetails = useCallback(() => {
    const selectedFilesDetails = [];

    for (let i = 0; i <= filesList.length - 1; i++) {
      selectedFilesDetails.push({
        name: filesList[i]?.name || 'UNKNOWN',
        size: filesList[i]?.size || 'UNKNOWN',
        type: filesList[i]?.type || 'UNKNOWN',
        base64String: URL.createObjectURL(filesList[i]),
      });
    }

    setSelectedFilesDetails(selectedFilesDetails);
  }, [filesList]);

  const removeSelectedImage = (index) => {
    const newFilesList = [];

    for (let i = 0; i <= filesList.length - 1; i++) {
      if (index === i) {
        continue;
      }
      newFilesList.push(filesList[i]);
    }

    setFilesList(newFilesList);
  };

  useEffect(() => {
    onChange(filesList);
    generateSelectedFilesDetails();
  }, [filesList, onChange, generateSelectedFilesDetails]);

  return (
    <>
      <StyledInput
        type='file'
        ref={inputRef}
        onChange={onChangeInput}
        multiple={multiple}
        disabled={disabled}
        {...rest}
      />
      <StyledFileInputContainer onClick={openFileSelector} disabled={disabled}>
        <FontAwesomeIcon icon={faCloudArrowUp} />
        <p>
          click to select file
          {multiple && `s`}
        </p>
      </StyledFileInputContainer>
      {selectedFilesDetails.length > 0 && (
        <StyledFilesListContainer preview={preview === true}>
          {selectedFilesDetails.map(({ name, size, base64String }, index) => (
            <StyledPreviewComponent key={index} preview={preview === true}>
              {preview === true && <Image source={base64String} alt={name} />}
              <p>
                {name} ({' '}
                {size !== 'UNKNOWN'
                  ? `${Math.floor(size / 1024).toFixed(2)} KB`
                  : 'UNKNOWN'}{' '}
                )
              </p>
              <FontAwesomeIcon
                icon={faTrash}
                onClick={() => !disabled && removeSelectedImage(index)}
                title='Delete'
              />
            </StyledPreviewComponent>
          ))}
        </StyledFilesListContainer>
      )}
    </>
  );
};

export default FileInput;
