import { MODULES, PERMISSION_TYPES } from './index';

const {
  DASHBOARD,
  ACCOUNTS_MANAGEMENT,
  ROLES_AND_PERMISSIONS,
  ACCOUNTS,
  USERS,
  CONFIGURATIONS,
  LANGUAGES,
  SMS_GATEWAY,
  CONTENT_MANAGEMENT,
  ANIMALS,
  POSTS,
  PENDING_POSTS,
  LOCALIZATION,
} = MODULES;

const { CREATE, READ, UPDATE, DELETE } = PERMISSION_TYPES;

const modulesMapper = [
  {
    moduleName: DASHBOARD,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: ACCOUNTS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: ROLES_AND_PERMISSIONS,
    masterModule: ACCOUNTS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
      },
      {
        permission: DELETE,
      },
    ],
  },
  {
    moduleName: ACCOUNTS,
    masterModule: ACCOUNTS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
      },
      {
        permission: DELETE,
      },
    ],
  },
  {
    moduleName: USERS,
    masterModule: ACCOUNTS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
      },
      {
        permission: DELETE,
      },
    ],
  },
  {
    moduleName: CONFIGURATIONS,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: LANGUAGES,
    masterModule: CONFIGURATIONS,
    permissions: [
      {
        permission: CREATE,
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
      },
      {
        permission: DELETE,
      },
    ],
  },
  {
    moduleName: SMS_GATEWAY,
    masterModule: CONFIGURATIONS,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: UPDATE,
      },
    ],
  },
  {
    moduleName: CONTENT_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: ANIMALS,
    masterModule: CONTENT_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
      },
      {
        permission: DELETE,
      },
    ],
  },
  {
    moduleName: POSTS,
    masterModule: CONTENT_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: UPDATE,
      },
      {
        permission: DELETE,
      },
    ],
  },
  {
    moduleName: PENDING_POSTS,
    masterModule: CONTENT_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: UPDATE,
      },
    ],
  },
  {
    moduleName: LOCALIZATION,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: UPDATE,
      },
    ],
  },
];

export default modulesMapper;
