const MODULES = {
  DASHBOARD: 'DASHBOARD',
  ACCOUNTS_MANAGEMENT: 'ACCOUNTS_MANAGEMENT',
  ROLES_AND_PERMISSIONS: 'ROLES_AND_PERMISSIONS',
  ACCOUNTS: 'ACCOUNTS',
  USERS: 'USERS',
  CONFIGURATIONS: 'CONFIGURATIONS',
  LANGUAGES: 'LANGUAGES',
  SMS_GATEWAY: 'SMS_GATEWAY',
  CONTENT_MANAGEMENT: 'CONTENT_MANAGEMENT',
  ANIMALS: 'ANIMALS',
  POSTS: 'POSTS',
  PENDING_POSTS: 'PENDING_POSTS',
  LOCALIZATION: 'LOCALIZATION',
};

const PERMISSION_TYPES = {
  CREATE: 'CREATE',
  READ: 'READ',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
};

const ROUTE_TYPES = {
  ALLOW_IF_LOGGEDIN: 'ALLOW_IF_LOGGEDIN',
  ALLOW_IF_NOT_LOGGEDIN: 'ALLOW_IF_NOT_LOGGEDIN',
};

const API_RESPONSE_TYPES = {
  SUCCESS: 'success',
  FAILURE: 'failure',
};

export { MODULES, PERMISSION_TYPES, ROUTE_TYPES, API_RESPONSE_TYPES };
