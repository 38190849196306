import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import {
  CustomConfirmButton,
  Switch,
  MessageBox,
  Form,
  Fieldset,
  TextInput,
  Button,
  ResetButton,
  Label,
} from '../../../components/FormElements';
import { TableBuilder } from '../../../components/TableElements';
import Pagination from '../../../components/Pagination/Pagination';
import FiltersButton from '../../../components/FiltersButton/FiltersButton';
import { StyledLink, StyledFiltersContainer } from '../../../components/Styled';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import {
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
} from '../../../utils';

const LanguagesList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [isFiltering, setIsFiltering] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 10,
  });
  const [languages, setLanguages] = useState([]);
  const [languageName, setLanguageName] = useState('');

  const readData = useCallback(
    (languageName = '') => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      setLanguages([]);
      API.get(
        `/configurations/languages?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&languageName=${languageName}`
      )
        .then((response) => {
          const { status, message, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.FAILURE) {
              setResponseStatus(status);
              setResponseMessage(message);
              setTimeout(() => {
                setResponseStatus('');
                setResponseMessage('');
              }, 3000);
            } else {
              setTotalRecords(pageInfo?.totalRecords);
            }
            setLanguages(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const updateData = (value, data) => {
    const newData = { ...data };
    newData.languageStatus = value === true ? `Active` : `Inactive`;
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.put(`/configurations/languages/${newData.id}`, newData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteData = (id) => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.delete(`/configurations/languages/${id}`)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          reloadData();
        } else {
          setIsLoading(false);
          setResponseStatus(status);
          setResponseMessage(message);
          setTimeout(() => {
            setResponseStatus('');
            setResponseMessage('');
          }, 3000);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      });
  };

  const reloadData = () => {
    readData(languageName);
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  const resetForm = () => {
    setLanguageName('');
    readData();
  };

  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      readData(languageName);
    }
  }, [paginationData, readData]);

  const { accountsPermissions, subRoutes } = props;
  let updateLink = '';
  let updateTitle = '';
  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;
  const canDelete =
    accountsPermissions.includes(PERMISSION_TYPES.DELETE) || false;
  if (canUpdate) {
    const { title, path } = subRoutes.find(
      ({ permissionRequired }) => permissionRequired === PERMISSION_TYPES.UPDATE
    );
    updateLink = path;
    updateTitle = title;
  }

  return (
    <>
      <PageHeading
        {...props}
        showCreate={true}
        canReload={true}
        reloadData={reloadData}
      />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? 'show' : ''}>
        <Form method='POST' action='#' onSubmit={filterData}>
          <Fieldset>
            <Label>Search</Label>
            <TextInput
              value={languageName}
              onChange={setLanguageName}
              placeholder='Search Language Name'
              maxLength={100}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading} type='submit'>
              Filter
            </Button>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={responseStatus === '' && languages.length === 0 && isLoading}
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          languages.length === 0
        }
        message={`No languages found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {languages.length > 0 && (
        <>
          <TableBuilder
            isLoading={languages.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: '',
                dataSelector: 'id',
                sticky: true,
                canSort: false,
                width: '50px',
                CellRenderer: (value) => (
                  <>
                    {canUpdate && (
                      <StyledLink
                        to={updateLink.replace(':id', value)}
                        title={updateTitle}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </StyledLink>
                    )}
                    {canDelete && (
                      <CustomConfirmButton onClick={() => deleteData(value)} />
                    )}
                  </>
                ),
              },
              {
                title: 'Language Name',
                dataSelector: 'languageName',
                dataType: 'string',
              },
              {
                title: 'Language Code',
                dataSelector: 'languageCode',
                dataType: 'string',
              },
              {
                title: 'Language Short Name',
                dataSelector: 'languageShortName',
                dataType: 'string',
                CellRenderer: (value) => (value ? value : 'N/A'),
              },
              {
                title: 'Status',
                dataSelector: 'languageStatus',
                dataType: 'string',
                align: 'center',
                canSort: false,
                CellRenderer: (value, item) => {
                  return (
                    <Switch
                      value={value === 'Active'}
                      onChange={(value) => updateData(value, item)}
                    />
                  );
                },
              },
            ]}
            tableData={languages}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(LanguagesList);
